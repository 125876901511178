.cardHideOverflow {
   background-color: rgb(255, 255, 255);
   overflow: hidden;
   /* color: rgb(255,255,255); */
}

.card-footer-light {
   padding: .75rem 1.25rem;
   background-color: rgba(255, 255, 255, .1);
   border-top: 1px solid rgba(255, 255, 255, .125);
}

.card-deck-overrides {
   margin-bottom: 32px;
   /* flex-flow: row wrap;
   margin-right: -15px;
   margin-left: -15px; */
}

@media (min-width: 768px) {
   .card-deck {
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px;
   }
}

.card-defaults {
   /* border: 1px solid rgb(33, 37, 41); */
   overflow: hidden;
   border-radius: .25rem;
   position: relative;
   display: flex;
   flex-direction: column;
   flex: 1 1 auto;
}

.lookingForCollaboratorCardImage {
   background: linear-gradient(rgba(0, 0, 0, 0.5),
         rgba(0, 0, 0, 0.5)),

         url(https://p0.pxfuel.com/preview/449/865/235/achievement-agreement-arms-asian.jpg);
   background-repeat: no-repeat;
   background-position-x: center;
   background-position-y: center;
   background-size: cover;
}

.lookingForGovCollaboratorCardImage {
   background: linear-gradient(rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5)),

      url(https://images.unsplash.com/photo-1556761175-b413da4baf72?ixlib=rb-1.2.1&auto=format&fit=crop&w=1934&q=80);
   background-repeat: no-repeat;
   background-position: fixed;
   background-size: cover;
}

.lookingForUnivCollaboratorCardImage {
   background: linear-gradient(rgba(0, 0, 0, 0.5),
         rgba(0, 0, 0, 0.5)),

      url(https://images.unsplash.com/photo-1552664730-d307ca884978?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80);
   background-repeat: no-repeat;
   background-position: fixed;
   background-size: cover;
}

.PasteQCodeCardImage {
   background: linear-gradient(rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5)),

      url(https://images.unsplash.com/photo-1515879218367-8466d910aaa4?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80);
   background-repeat: no-repeat;
   background-position: fixed;
   background-size: cover;
}

.underConstructionCardImage {
   height: 100%;
   --blur-radius: 10px;
   filter: blur(var(--blur-radius));
   -webkit-filter: blur(var(--blur-radius));
   -moz-filter: blur(var(--blur-radius));
   -o-filter: blur(var(--blur-radius));
   -ms-filter: blur(var(--blur-radius));
   background: linear-gradient(rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5)),

      url(https://images.unsplash.com/photo-1478486982180-2de2fafa19f9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1502&q=80);
   background-repeat: no-repeat;
   background-size: cover;
   transform: scale(1.2);
}


.newFeaturesBanner {
   position: absolute;
   left: 50%;
   top: 50%;
   z-index: 2;
   text-align: center;
   transform: translate(-50%, -50%);
   /* background-color: rgba(0, 0, 0, 0.4); */
   width: 100%;
   font-size: 1.8em;
   padding-top: 1em;
   padding-bottom: 1em;
}

.card-no-border {
    border: none;
}
