/* .table-curved {
   border-collapse: separate;
} */

.table-curved {
   border-radius: 6px;
   border-left: 0px;
}

/* .table-curved td,
.table-curved th {
   border-left: 1px solid #ccc;
   border-top: 1px solid #ccc;
} */

/* .table-curved th {
   border-top: none;
} */

.table-curved > thead > tr > th {
   border-top: none;
}

.table-curved>thead>tr>th:first-child {
   border-radius: 6px 0 0 0;
}

.table-curved>thead>tr>th:last-child {
   border-radius: 0 6px 0 0;
}

.table-curved>thead>tr>th:only-child {
   border-radius: 6px 6px 0 0;
}

.table-curved>thead>tr>tr:last-child td:first-child {
   border-radius: 0 0 0 6px;
}

.table-curved>thead>tr>tr:last-child td:last-child {
   border-radius: 0 0 6px 0;
}