@font-face {
   font-family: 'c4-icon';
   src: url('fonts/c4-icon.eot?vw3pch');
   src: url('fonts/c4-icon.eot?vw3pch#iefix') format('embedded-opentype'),
      url('fonts/c4-icon.ttf?vw3pch') format('truetype'),
      url('fonts/c4-icon.woff?vw3pch') format('woff'),
      url('fonts/c4-icon.svg?vw3pch#c4-icon') format('svg');
   font-weight: normal;
   font-style: normal;
}

html,
body {
   height: 100%;
}

.footer {
   flex-shrink: 0;
   text-align: center;
   background-color: tomato;
   color: white;
}

#root {
   display: flex;
   flex-direction: column;
   height: 100%;
}

.content {
   flex: 1 0 auto;
   /* padding: 10px; */
}

.icon {
   display: inline-block;
   width: 1em;
   height: 1em;
   stroke-width: 0;
   stroke: currentColor;
   fill: currentColor;
}

.centerd-pagination {
     display: table;
     margin: 0 auto;
}
